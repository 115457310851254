<template>
  <div id="event-box">
    <h3>
      Únete y sé parte de la diferencia aportando a mitigar la erosión costera.
    </h3>
    <div class="event-container">
      <!--            Event -->
      <div class="event-1 event-item">
        <div class="event-content event-content-1 ">
          <h4>Playa La Palmita, Barceloneta</h4>
          <p>12 de octubre de 2024</p>
        </div>
        <div class="event-content event-content-2">
          <a
            target="_blank"
            href="https://www.eventbrite.com/e/uno-con-el-ambiente-barceloneta-tickets-1036815151027"
            class="btn btn-default btn-orange"
            >Regístrate aquí</a
          >
        </div>
      </div>
<!--            <h6 class="center">¡Hemos alcanzado nuestra meta de voluntarios! <br />Pendiente a próximos eventos de siembra y únete a nosotros.</h6>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "blockBoxEvent",
  created() {
    var scripts = ["https://www.eventbrite.com/static/widgets/eb_widgets.js"];
    scripts.forEach(script => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      document.head.appendChild(tag);
    });
  },
  mounted() {
    var exampleCallback = function() {
      console.log("Order complete!");
    };

    window.EBWidgets.createWidget({
      // Required
      widgetType: "checkout",
      eventId: "625302886767",
      iframeContainerId: "eventbrite-widget-container-625302886767",

      // Optional
      iframeContainerHeight: 825, // Widget height in pixels. Defaults to a minimum of 425px if not provided
      onOrderComplete: exampleCallback // Method called when an order has successfully completed
    });
  }
};
</script>

<style scoped>
.center {
  width: 100% !important;
}

.center * {
  text-align: center;
}
</style>
